import createSvgIcon from "@mui/material/utils/createSvgIcon";
const Food = createSvgIcon(<svg fill="currentColor" viewBox="0 0 512 512">
    <g>
      <g>
        <path d="M145.736,377.191l-2.159-10.249c-0.854-4.054-4.83-6.649-8.887-5.794c-4.054,0.854-6.649,4.832-5.794,8.887l2.159,10.248
c0.744,3.533,3.861,5.958,7.333,5.958c0.512,0,1.032-0.054,1.554-0.163C143.996,385.224,146.59,381.246,145.736,377.191z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M162.791,458.152l-9.859-46.799c-0.854-4.055-4.832-6.644-8.887-5.795c-4.054,0.854-6.649,4.833-5.794,8.887l9.859,46.799
c0.744,3.533,3.861,5.958,7.333,5.958c0.512,0,1.032-0.054,1.554-0.163C161.051,466.185,163.647,462.206,162.791,458.152z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M229.913,457.626l-6.405-91.21c-0.29-4.133-3.878-7.254-8.009-6.958c-4.133,0.29-7.248,3.876-6.958,8.009l6.405,91.211
c0.277,3.954,3.571,6.976,7.476,6.976c0.176,0,0.355-0.006,0.533-0.019C227.088,465.345,230.204,461.759,229.913,457.626z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M298.857,432.981c-4.126-0.3-7.719,2.825-8.009,6.958l-1.279,18.214c-0.29,4.132,2.825,7.718,6.958,8.008
c0.179,0.013,0.357,0.019,0.533,0.019c3.904,0,7.198-3.022,7.476-6.976l1.279-18.214
C306.106,436.857,302.99,433.271,298.857,432.981z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M303.983,359.985c-4.122-0.292-7.719,2.825-8.009,6.958l-2.681,38.177c-0.29,4.132,2.826,7.718,6.958,8.008
c0.179,0.013,0.357,0.019,0.533,0.019c3.904,0,7.198-3.022,7.476-6.976l2.681-38.177
C311.231,363.861,308.116,360.275,303.983,359.985z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M377.469,359.601c-4.055-0.858-8.033,1.741-8.887,5.794l-19.214,91.21c-0.855,4.055,1.739,8.034,5.794,8.888
c0.522,0.11,1.041,0.163,1.554,0.163c3.472,0,6.589-2.424,7.333-5.958l19.214-91.21
C384.117,364.434,381.523,360.455,377.469,359.601z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M481.195,279.919h-38.664v-75.04c0-3.202-0.783-6.396-2.264-9.235l-16.334-31.316c6.539-1.642,11.401-7.552,11.401-14.593
v-11.088c0-8.305-6.756-15.062-15.061-15.062H316.389c-8.305,0-15.062,6.757-15.062,15.062v10.977
c-2.893-5.505-6.545-10.644-10.921-15.259c-9.423-9.94-14.613-22.957-14.613-36.654v-7.373c2.069-1.847,3.38-4.525,3.38-7.509
v-72.75C279.174,4.521,274.653,0,269.096,0h-45.108c-5.557,0-10.078,4.521-10.078,10.078v72.75c0,2.985,1.312,5.662,3.38,7.509
v7.373c0,13.697-5.19,26.714-14.613,36.654c-12.078,12.739-18.729,29.422-18.729,46.976v61.317l-8.851-51.734
c-2.132-12.461-9.592-23.305-20.469-29.75c-10.876-6.445-23.969-7.781-35.924-3.667l-4.652,1.601
c-3.458-22.572,0.641-46.602,0.693-46.899c0.716-4.078-2.007-7.967-6.086-8.686c-4.081-0.722-7.971,2.005-8.691,6.086
c-0.21,1.192-3.756,21.847-1.672,44.21L74.46,84.556c-1.348-3.918-5.618-6.002-9.535-4.653c-3.918,1.348-6.001,5.618-4.653,9.535
l21.18,61.543c-0.457-0.825-0.921-1.653-1.397-2.487c-13.678-23.992-27.644-38.852-41.511-44.168
c-3.867-1.484-8.207,0.451-9.69,4.319c-1.483,3.868,0.451,8.207,4.319,9.69c16.589,6.36,32.91,34.096,42.428,54.006l-5.306,1.826
c-11.954,4.114-21.452,13.226-26.059,24.999c-4.606,11.774-3.813,24.913,2.175,36.046l24.046,44.707H30.804
c-8.83,0-16.014,7.184-16.014,16.014v21.151c0,8.83,7.184,16.014,16.014,16.014h17.084l33.351,150.845
C84.892,500.463,99.259,512,116.177,512h279.644c16.919,0,31.286-11.537,34.938-28.057l33.352-150.845h17.084
c8.83,0,16.014-7.184,16.014-16.014v-21.151C497.209,287.103,490.025,279.919,481.195,279.919z M426.964,202.584
c0.369,0.706,0.563,1.499,0.563,2.296v75.04h-11.884v-51.427c0-5.838-4.75-10.589-10.589-10.589h-73.446
c-5.839,0-10.589,4.75-10.589,10.589v51.427h-11.884v-72.28v-2.76c0-0.796,0.194-1.589,0.563-2.296l19.708-37.787h77.849
L426.964,202.584z M400.639,232.907v47.012h-64.616v-47.012H400.639z M316.389,138.59l103.942,0.058l-0.038,11.144
c0,0-0.005,0.001-0.02,0.001l-103.942-0.058L316.389,138.59z M312.729,164.328l-4.193,8.04c-0.532-4.048-1.426-8.014-2.658-11.866
C307.755,162.334,310.107,163.67,312.729,164.328z M228.913,15.004h35.256v62.898h-35.256V15.004z M213.564,144.688
c12.077-12.74,18.729-29.423,18.729-46.977v-4.805h28.496v4.805c0,17.554,6.652,34.238,18.729,46.977
c9.423,9.939,14.613,22.956,14.613,36.653v18.797h-57.067c-8.336,0-15.117,6.782-15.117,15.117v64.664h-22.996v-98.578h-0.001
C198.95,167.644,204.14,154.627,213.564,144.688z M294.132,215.143v64.777h-57.18v-64.664c0-0.063,0.051-0.113,0.113-0.113
H294.132z M66.307,240.529l-6.682-12.424c-3.899-7.25-4.416-15.806-1.416-23.472c2.999-7.666,9.184-13.6,16.968-16.279
l48.409-16.66c7.784-2.679,16.311-1.809,23.392,2.387c7.082,4.197,11.941,11.258,13.329,19.372l14.793,86.465h-66.133
l13.499-4.646c3.918-1.348,6.001-5.618,4.652-9.535c-1.348-3.918-5.617-6.001-9.535-4.653l-33.936,11.68L73.51,253.917
l45.871-15.787c3.918-1.348,6.001-5.618,4.652-9.535c-1.348-3.918-5.617-6.001-9.535-4.653L66.307,240.529z M416.109,480.704
c-2.121,9.592-10.463,16.292-20.288,16.292H116.177c-9.824,0-18.167-6.7-20.287-16.292L63.254,333.098h385.491L416.109,480.704z
 M482.206,317.084L482.206,317.084c-0.001,0.557-0.454,1.01-1.011,1.01H30.804c-0.557,0-1.01-0.453-1.01-1.01v-21.151
c0-0.557,0.453-1.01,1.01-1.01h450.391c0.557,0,1.01,0.453,1.01,1.01V317.084z" />
      </g>
    </g>
  </svg>, "Food");
export default Food;