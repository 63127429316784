import createSvgIcon from "@mui/material/utils/createSvgIcon";
const Living = createSvgIcon(<svg viewBox="0 0 19 19" fill="none">
    <g clipPath="url(#clip0_6800_50505)">
      <path d="M17.9336 11.79H16.8086V10.1025C16.8086 10.0279 16.779 9.95641 16.7262 9.90367C16.6735 9.85092 16.6019 9.82129 16.5273 9.82129H9.21484C9.14025 9.82129 9.06871 9.85092 9.01597 9.90367C8.96323 9.95641 8.93359 10.0279 8.93359 10.1025V11.79H7.80859C7.734 11.79 7.66246 11.8197 7.60972 11.8724C7.55698 11.9252 7.52734 11.9967 7.52734 12.0713V15.4463C7.52734 15.5209 7.55698 15.5924 7.60972 15.6452C7.66246 15.6979 7.734 15.7275 7.80859 15.7275H8.08984V16.29H4.99609V16.8525H17.9336V16.29H17.6523V15.7275H17.9336C18.0082 15.7275 18.0797 15.6979 18.1325 15.6452C18.1852 15.5924 18.2148 15.5209 18.2148 15.4463V12.0713C18.2148 11.9967 18.1852 11.9252 18.1325 11.8724C18.0797 11.8197 18.0082 11.79 17.9336 11.79ZM9.49609 13.4775H16.2461V14.04H9.49609V13.4775ZM16.2461 12.0713V12.915H13.1523V10.3838H16.2461V12.0713ZM9.49609 10.3838H12.5898V12.915H9.49609V10.3838ZM8.65234 16.29V15.7275H9.21484V16.29H8.65234ZM15.9648 15.7275V16.29H9.77734V15.7275H15.9648ZM17.0898 16.29H16.5273V15.7275H17.0898V16.29ZM17.6523 15.165H8.08984V12.3525H8.93359V14.3213C8.93359 14.3959 8.96323 14.4674 9.01597 14.5202C9.06871 14.5729 9.14025 14.6025 9.21484 14.6025H16.5273C16.6019 14.6025 16.6735 14.5729 16.7262 14.5202C16.779 14.4674 16.8086 14.3959 16.8086 14.3213V12.3525H17.6523V15.165Z" fill="currentColor" />
      <path d="M6.40234 14.6025C6.47694 14.6025 6.54847 14.5729 6.60122 14.5202C6.65396 14.4674 6.68359 14.3959 6.68359 14.3213V5.32129C6.68359 5.2467 6.65396 5.17516 6.60122 5.12242C6.54847 5.06967 6.47694 5.04004 6.40234 5.04004H1.05859C0.984002 5.04004 0.912465 5.06967 0.85972 5.12242C0.806975 5.17516 0.777344 5.2467 0.777344 5.32129V14.3213C0.777344 14.3959 0.806975 14.4674 0.85972 14.5202C0.912465 14.5729 0.984002 14.6025 1.05859 14.6025H6.40234ZM3.02734 10.665H3.30859V12.915H1.33984V8.8701L1.74681 9.07373C1.79365 9.09691 1.83304 9.13276 1.86054 9.1772C1.88803 9.22165 1.90251 9.27291 1.90234 9.32516V9.54004C1.90234 9.83841 2.02087 10.1246 2.23185 10.3355C2.44283 10.5465 2.72898 10.665 3.02734 10.665ZM4.99609 10.1025H5.55859C5.75666 10.1014 5.95084 10.0474 6.12109 9.94616V12.915H4.99609V10.1025ZM6.12109 5.60254V8.97754C6.12109 9.12672 6.06183 9.2698 5.95634 9.37529C5.85085 9.48078 5.70778 9.54004 5.55859 9.54004H4.99609V9.09398L5.47619 8.61388L5.0785 8.2162L4.516 8.7787C4.46325 8.83143 4.43361 8.90295 4.43359 8.97754V13.1963C4.43359 13.2709 4.46323 13.3424 4.51597 13.3952C4.56871 13.4479 4.64025 13.4775 4.71484 13.4775H6.12109V14.04H1.33984V13.4775H3.58984C3.66444 13.4775 3.73597 13.4479 3.78872 13.3952C3.84146 13.3424 3.87109 13.2709 3.87109 13.1963V9.25879C3.87109 9.19794 3.85136 9.13872 3.81484 9.09004L2.97109 7.96504L2.52109 8.30254L3.30859 9.35245V10.1025H3.02734C2.87816 10.1025 2.73509 10.0433 2.6296 9.93779C2.52411 9.8323 2.46484 9.68922 2.46484 9.54004V9.32516C2.46539 9.16838 2.42199 9.01457 2.33957 8.8812C2.25715 8.74782 2.139 8.64021 1.99853 8.57057L1.33984 8.24123V5.60254H6.12109Z" fill="currentColor" />
      <path d="M3.30859 16.29H1.05859V16.8525H3.30859V16.29Z" fill="currentColor" />
      <path d="M4.43359 16.29H3.87109V16.8525H4.43359V16.29Z" fill="currentColor" />
      <path d="M8.65234 6.72754H16.8086C16.8832 6.72754 16.9547 6.69791 17.0075 6.64516C17.0602 6.59242 17.0898 6.52088 17.0898 6.44629V5.32129C17.0898 5.2467 17.0602 5.17516 17.0075 5.12242C16.9547 5.06967 16.8832 5.04004 16.8086 5.04004H15.9648V3.35254C15.9648 3.27795 15.9352 3.20641 15.8825 3.15367C15.8297 3.10092 15.7582 3.07129 15.6836 3.07129H14.8398V2.50879C14.8398 2.4342 14.8102 2.36266 14.7575 2.30992C14.7047 2.25717 14.6332 2.22754 14.5586 2.22754H10.0586C9.984 2.22754 9.91246 2.25717 9.85972 2.30992C9.80698 2.36266 9.77734 2.4342 9.77734 2.50879V5.04004H8.65234C8.57775 5.04004 8.50621 5.06967 8.45347 5.12242C8.40073 5.17516 8.37109 5.2467 8.37109 5.32129V6.44629C8.37109 6.52088 8.40073 6.59242 8.45347 6.64516C8.50621 6.69791 8.57775 6.72754 8.65234 6.72754ZM15.4023 3.63379V5.04004H14.8398V3.63379H15.4023ZM14.2773 5.04004H13.7148V2.79004H14.2773V5.04004ZM13.1523 5.04004H12.5898V2.79004H13.1523V5.04004ZM12.0273 5.04004H11.4648V2.79004H12.0273V5.04004ZM10.3398 2.79004H10.9023V5.04004H10.3398V2.79004ZM8.93359 5.60254H16.5273V6.16504H8.93359V5.60254Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_6800_50505">
        <rect width="18" height="18" fill="white" transform="translate(0.496094 0.540039)" />
      </clipPath>
    </defs>
  </svg>, "Living");
export default Living;