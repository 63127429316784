import createSvgIcon from "@mui/material/utils/createSvgIcon";
const GoldPackageIcon = createSvgIcon(<svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 8C0 3.58172 3.58172 0 8 0H92C96.4183 0 100 3.58172 100 8V92C100 96.4183 96.4183 100 92 100H8C3.58172 100 0 96.4183 0 92V8Z" fill="#FFF9E5" />
    <g clipPath="url(#clip0_8579_71335)">
      <path d="M50.4336 30.7422L56.2531 33.0792L58.6696 38.7059C58.8014 38.9749 59.0795 39.1258 59.3724 39.1258C59.6654 39.1258 59.9439 38.9749 60.0757 38.7059L62.4921 33.0792L68.3069 30.7422C68.5853 30.6147 68.7462 30.3456 68.7462 30.0623C68.7462 29.779 68.5853 29.5101 68.3069 29.3827L62.4922 27.047L60.0758 21.4248C59.9492 21.1558 59.668 21 59.375 21C59.0821 21 58.8041 21.1558 58.6723 21.4248L56.2558 27.047L50.4363 29.3839C50.158 29.5114 50.0013 29.7803 50.0013 30.0635C50.0013 30.3467 50.1524 30.6153 50.4336 30.7422ZM62.6797 51.2688L50.3985 49.5378L44.9141 38.7512C43.9326 36.8254 41.0797 36.8107 40.0836 38.7512L34.5899 49.4449L22.3098 51.2688C20.0975 51.484 19.2099 54.2141 20.8211 55.7207L29.7122 64.1035L27.7082 75.9527C27.342 78.0915 29.6653 79.6967 31.6129 78.6919L42.604 73.098L53.5891 78.6919C54.4973 79.1592 55.5965 79.0754 56.4309 78.4949C57.1458 77.9143 57.6755 76.9191 57.4996 75.9562L55.2969 64.1035L64.1938 55.7207C65.7852 54.2254 64.8946 51.484 62.6797 51.2688ZM79.6485 45.8426L75.0055 43.9587L73.0579 39.4694C72.9553 39.257 72.7355 39.1292 72.5011 39.1292C72.2667 39.1292 72.0471 39.257 71.9446 39.4694L69.9969 43.9587L65.3516 45.8426C65.1319 45.9417 64.9998 46.1536 64.9998 46.3802C64.9998 46.6068 65.1319 46.8196 65.3516 46.9188L69.9946 48.8026L71.9422 53.292C72.0448 53.5044 72.2644 53.6312 72.4987 53.6312C72.7331 53.6312 72.953 53.5044 73.0555 53.292L75.0032 48.8026L79.6461 46.9188C79.8711 46.8168 80 46.6016 80 46.375C80 46.1484 79.8711 45.9332 79.6485 45.8426Z" fill="#FFB808" />
    </g>
    <defs>
      <clipPath id="clip0_8579_71335">
        <rect width="60" height="58" fill="white" transform="translate(20 21)" />
      </clipPath>
    </defs>
  </svg>, "GoldPackageIcon");
export default GoldPackageIcon;