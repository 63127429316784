import createSvgIcon from "@mui/material/utils/createSvgIcon";
const Eye = createSvgIcon(<svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.666687 8.5C0.666687 8.5 3.33335 3.16667 8.00002 3.16667C12.6667 3.16667 15.3334 8.5 15.3334 8.5C15.3334 8.5 12.6667 13.8333 8.00002 13.8333C3.33335 13.8333 0.666687 8.5 0.666687 8.5Z" stroke="#7D879C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 10.5C9.10457 10.5 10 9.60457 10 8.5C10 7.39543 9.10457 6.5 8 6.5C6.89543 6.5 6 7.39543 6 8.5C6 9.60457 6.89543 10.5 8 10.5Z" stroke="#7D879C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <g clipPath="url(#clip0_8501_71183)"></g>
    <defs>
      <clipPath id="clip0_8501_71183">
        <rect width="16" height="16" fill="currentColor" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>, "Eye");
export default Eye;