import createSvgIcon from "@mui/material/utils/createSvgIcon";
const Lightning = createSvgIcon(<svg fill="currentColor" viewBox="0 0 64 64">
    <g>
      <path d="M23.7766 25.3112C23.4095 24.7265 22.6382 24.55 22.0533 24.9171L16.6501 28.3086C16.0653 28.6756 15.8888 29.4471 16.256 30.0318C16.4933 30.4101 16.9 30.6175 17.3158 30.6175C17.5428 30.6175 17.7726 30.5556 17.9792 30.426L23.3825 27.0345C23.9672 26.6675 24.1437 25.896 23.7766 25.3112Z" />
      <path d="M15.3327 30.6137C14.9662 30.0288 14.1953 29.8515 13.6098 30.2177L13.5847 30.2335C12.9995 30.6 12.8223 31.3712 13.1887 31.9564C13.4259 32.3352 13.8329 32.5431 14.2493 32.5431C14.4759 32.5431 14.7053 32.4815 14.9115 32.3524L14.9367 32.3366C15.5218 31.9701 15.699 31.1989 15.3327 30.6137Z" />
      <path d="M63.7869 0.552501C63.4358 0.0303768 62.7512 -0.149373 62.1889 0.133127L25.186 18.714C24.7681 18.9237 24.5024 19.3491 24.497 19.8166C24.4915 20.2841 24.7474 20.7155 25.1602 20.9348L32.4493 24.809L15.8201 35.2474C15.4421 35.4847 15.2194 35.9053 15.2355 36.3512C15.2515 36.7971 15.504 37.2006 15.898 37.4099L23.9972 41.7147L0.439401 61.7986C-0.0493478 62.2153 -0.142848 62.9344 0.223027 63.4621C0.464276 63.8099 0.85315 64 1.25115 64C1.45715 64 1.6654 63.9491 1.8569 63.8429L39.8537 42.758C40.2543 42.5357 40.5012 42.1119 40.4971 41.6537C40.4929 41.1955 40.2384 40.7762 39.8337 40.5612L32.4305 36.6262L49.7009 25.509C50.0753 25.268 50.2932 24.8458 50.2729 24.401C50.2527 23.9561 49.9973 23.5556 49.6026 23.3496L43.2093 20.014L63.573 2.19075C64.0465 1.77637 64.1383 1.07462 63.7869 0.552501ZM40.1878 19.3365C39.8773 19.6082 39.7209 20.0158 39.7698 20.4255C39.8189 20.8352 40.0669 21.1943 40.4328 21.3852L46.5392 24.5711L29.2846 35.6781C28.9117 35.9182 28.6937 36.3381 28.7123 36.7813C28.7307 37.2246 28.9828 37.6248 29.3746 37.8329L36.6285 41.6885L11.5724 55.5925L27.0177 42.4248C27.3327 42.1563 27.4943 41.7493 27.4495 41.3379C27.4046 40.9264 27.159 40.564 26.7935 40.3698L18.9789 36.2163L35.6082 25.7776C35.9861 25.5403 36.209 25.1197 36.1928 24.6738C36.1768 24.228 35.9243 23.8245 35.5303 23.6151L28.4697 19.8625L54.5487 6.76711L40.1878 19.3365Z" />
      <path d="M47.41 43.4252C47.0744 42.8217 46.3129 42.6049 45.7101 42.9404L45.6841 42.9549C45.0808 43.2906 44.8637 44.0517 45.1994 44.6551C45.4279 45.0659 45.8538 45.2976 46.2928 45.2976C46.4986 45.2976 46.7071 45.2467 46.8994 45.1397L46.9254 45.1252C47.5287 44.7896 47.7458 44.0284 47.41 43.4252Z" />
      <path d="M44.2445 45.1826C43.9094 44.579 43.1484 44.3611 42.5449 44.6963L36.9669 47.7918C36.3633 48.1268 36.1455 48.8876 36.4804 49.4913C36.7088 49.9028 37.135 50.1349 37.5745 50.1349C37.7798 50.1349 37.9879 50.0843 38.18 49.9776L43.758 46.8821C44.3616 46.5471 44.5794 45.7863 44.2445 45.1826Z" />
    </g>
  </svg>, "Lightning");
export default Lightning;