import createSvgIcon from "@mui/material/utils/createSvgIcon";
const Pets = createSvgIcon(<svg fill="currentColor" viewBox="0 0 511.999 511.999">
    <g>
      <g>
        <g>
          <path d="M295.999,281.376c-5.52,0-10,4.48-10,10s4.48,10,10,10c5.52,0,10-4.48,10-10S301.519,281.376,295.999,281.376z" />
          <path d="M385.22,335.218c-9.374-9.369-32.107-34.727-50.041-75.851c-13.336-31.998-44.347-52.669-79.028-52.669
		  c-34.674,0-65.68,20.674-79.019,52.679c-22.5,51.606-53.238,78.916-53.533,79.174c-0.036,0.031-0.071,0.063-0.106,0.094
		  c-18.13,16.233-28.528,39.487-28.528,63.799c0,29.721,14.153,55.568,38.705,71.945c22.876,15.262,51.229,19.838,77.784,12.557
		  c13.308-3.646,28.711-5.574,44.547-5.574c16.103,0,31.734,1.992,45.203,5.76c25.775,7.215,54.066,3.259,77.421-12.172
		  c24.834-16.409,39.232-42.5,39.232-72.516C417.857,376.045,405.971,351.58,385.22,335.218z M367.597,458.273
		  c-18.023,11.908-40.258,15.406-61.005,9.599c-15.201-4.253-32.695-6.5-50.594-6.5c-17.596,0-34.827,2.174-49.834,6.286
		  c-20.966,5.749-43.344,2.138-61.398-9.906c-18.85-12.575-29.804-32.234-29.804-55.309c0-18.614,7.956-36.421,21.829-48.863
		  c2.134-1.871,34.586-30.925,58.706-86.29c0.022-0.052,0.045-0.104,0.066-0.156c10.208-24.564,33.988-40.436,60.584-40.436
		  c26.606-0.001,50.391,15.868,60.596,40.426c0.021,0.052,0.044,0.104,0.066,0.156c19.586,44.95,44.804,72.7,54.736,82.546
		  c0.286,0.282,0.588,0.548,0.904,0.794c16.146,12.545,25.405,31.434,25.405,51.823
		  C397.856,425.71,386.724,445.636,367.597,458.273z" />
          <path d="M323.24,321.186c-3.014-4.627-9.208-5.938-13.837-2.923c-4.629,3.016-5.937,9.211-2.923,13.838
		  c15.217,23.363,29.645,38.923,36.893,46.119c3.918,3.89,10.25,3.869,14.143-0.051c3.891-3.919,3.868-10.251-0.051-14.143
		  C350.781,357.39,337.447,343.001,323.24,321.186z" />
          <path d="M223.59,157.945c9.609-16.795,13.615-39.102,10.991-61.195c-5.076-43.013-34.741-79.046-67.884-75.066
		  c-14.966,1.771-28.272,11.595-37.473,27.662c-9.603,16.782-13.609,39.089-10.991,61.198
		  C126.524,180.789,191.917,213.272,223.59,157.945z M146.582,59.281c5.474-9.563,14.013-17.879,25.02-17.879
		  c19.681,0,39.427,26.419,43.119,57.699c2.13,17.933-0.964,35.76-8.488,48.91c-23.047,40.264-62.693,6.318-68.139-39.815
		  C135.969,90.249,139.064,72.42,146.582,59.281z" />
          <path d="M127.802,265.5c2.895-17.823-0.623-37.534-9.905-55.503c-9.286-17.973-23.328-32.25-39.541-40.202
		  c-17.219-8.446-34.853-8.882-49.653-1.229c-14.8,7.647-24.642,22.28-27.716,41.207c-2.895,17.824,0.623,37.534,9.905,55.505
		  c9.287,17.968,23.329,32.242,39.542,40.194c16.09,7.892,33.916,9.367,49.65,1.238C114.886,299.063,124.73,284.427,127.802,265.5z
		   M90.905,288.941c-9.03,4.668-20.274,4.161-31.663-1.425c-12.39-6.077-23.25-17.237-30.581-31.42
		  c-7.327-14.185-10.145-29.496-7.933-43.117c2.033-12.518,8.127-21.979,17.158-26.645c0.001-0.001,0.002-0.001,0.003-0.002
		  c9.986-5.163,21.552-3.539,31.66,1.419c12.389,6.077,23.249,17.238,30.58,31.426c7.327,14.185,10.145,29.498,7.933,43.118
		  C106.03,274.813,99.936,284.275,90.905,288.941z" />
          <path d="M330.998,186.089c30.315,0,57.988-33.098,62.999-75.353c5.325-45.014-17.355-89.143-53.38-89.143
		  c-30.32,0-57.99,33.099-62.989,75.353C272.297,142.042,295.044,186.089,330.998,186.089z M297.49,99.295
		  c3.702-31.278,23.452-57.703,43.129-57.703c0.926,0,1.724,0.045,2.439,0.138c10.663,1.185,18.917,10.504,23.779,20.019
		  c6.614,12.941,9.274,29.939,7.3,46.634c-3.843,32.404-25.321,60.197-45.577,57.568c-0.037-0.004-0.074-0.009-0.111-0.014
		  c-9.093-1.07-17.718-8.36-23.662-20C298.175,132.995,295.516,115.995,297.49,99.295z" />
          <path d="M511.013,209.813c-6.324-38.946-41.53-57.564-77.369-39.978c-33.529,16.448-55.437,58.818-49.446,95.706
		  c6.399,39.407,41.956,57.344,77.368,39.972C495.105,289.062,517.003,246.697,511.013,209.813z M452.758,287.556
		  c-11.388,5.585-22.632,6.09-31.663,1.425v0.001c-9.031-4.667-15.125-14.131-17.157-26.647
		  c-4.572-28.156,12.838-61.947,38.514-74.546c23.53-11.543,44.613-0.675,48.82,25.229
		  C495.836,241.122,478.481,274.94,452.758,287.556z" />
        </g>
      </g>
    </g>
  </svg>, "Pets");
export default Pets;