"use client";

import createSvgIcon from "@mui/material/utils/createSvgIcon";
const CreditCardVerified = createSvgIcon(<svg fill="currentColor" viewBox="0 0 64 64">
    <g>
      <path d="M62 40C60.896 40 60 40.896 60 42V56H4V32H26C27.104 32 28 31.104 28 30C28 28.896 27.104 28 26 28H4V20H26C27.104 20 28 19.104 28 18C28 16.896 27.104 16 26 16H4C1.792 16 0 17.792 0 20V56C0 58.208 1.792 60 4 60H60C62.208 60 64 58.208 64 56V42C64 40.896 63.104 40 62 40Z" />
      <path d="M18 40H10C8.896 40 8 40.896 8 42C8 43.104 8.896 44 10 44H18C19.104 44 20 43.104 20 42C20 40.896 19.104 40 18 40Z" />
      <path d="M62.788 10.1595L48.788 4.15949C48.28 3.94749 47.716 3.94749 47.208 4.15949L33.208 10.1595C32.476 10.4755 32 11.1995 32 11.9995V19.9995C32 31.0035 36.068 37.4355 47.004 43.7355C47.312 43.9115 47.656 43.9995 48 43.9995C48.344 43.9995 48.688 43.9115 48.996 43.7355C59.932 37.4515 64 31.0195 64 19.9995V11.9995C64 11.1995 63.524 10.4755 62.788 10.1595ZM60 19.9995C60 29.2355 56.944 34.3195 48 39.6795C39.056 34.3075 36 29.2235 36 19.9995V13.3195L48 8.17549L60 13.3195V19.9995Z" />
      <path d="M55.2521 16.4354C54.3921 15.7554 53.1361 15.8874 52.4401 16.7474L46.1521 24.6114L43.6641 20.8914C43.0441 19.9714 41.8001 19.7274 40.8921 20.3354C39.9761 20.9474 39.7241 22.1914 40.3361 23.1074L44.3361 29.1074C44.6921 29.6394 45.2761 29.9674 45.9161 29.9994C45.9441 29.9994 45.9761 29.9994 46.0001 29.9994C46.6041 29.9994 47.1801 29.7274 47.5641 29.2474L55.5641 19.2474C56.2521 18.3834 56.1161 17.1274 55.2521 16.4354Z" />
    </g>
  </svg>, "CreditCardVerified");
export default CreditCardVerified;