import createSvgIcon from "@mui/material/utils/createSvgIcon";
const Milk = createSvgIcon(<svg fill="currentColor" viewBox="0 0 20 20">
    <g>
      <path d="M3.43751 17.8125V10.3125C3.43636 9.32373 3.63725 8.34515 4.02786 7.4368L4.67149 5.93473C4.72645 5.80691 4.77848 5.67547 4.82739 5.54234C5.59613 5.40467 6.38452 5.42061 7.14708 5.58922C7.61003 5.69248 8.0829 5.74468 8.55723 5.74488C8.90396 5.74478 9.25012 5.71688 9.59239 5.66145C9.62755 5.75289 9.66462 5.84418 9.70333 5.93461L10.3471 7.43699L10.9216 7.1909L10.2779 5.68863C10.2094 5.52848 10.1464 5.36582 10.0904 5.20457C9.85779 4.53436 9.72434 3.83378 9.69422 3.125H10C10.0829 3.125 10.1624 3.09208 10.221 3.03347C10.2796 2.97487 10.3125 2.89538 10.3125 2.8125V1.5625C10.3122 1.31395 10.2134 1.07566 10.0376 0.899907C9.86185 0.724154 9.62356 0.625289 9.37501 0.625H5.00001C4.75145 0.625289 4.51317 0.724154 4.33741 0.899907C4.16166 1.07566 4.06279 1.31395 4.06251 1.5625V2.8125C4.06251 2.89538 4.09543 2.97487 4.15403 3.03347C4.21264 3.09208 4.29213 3.125 4.37501 3.125H4.68075C4.6512 3.82081 4.52206 4.5088 4.29727 5.16797C4.23684 5.34473 4.16958 5.51953 4.09715 5.68809L3.4534 7.19043C3.02935 8.17661 2.81126 9.23902 2.81251 10.3125V17.8125C2.81297 18.2268 2.97774 18.6239 3.27066 18.9168C3.56359 19.2098 3.96075 19.3745 4.37501 19.375H9.37501V18.75H4.37501C4.12645 18.7497 3.88817 18.6508 3.71241 18.4751C3.53666 18.2993 3.43779 18.0611 3.43751 17.8125ZM4.68751 1.5625C4.6876 1.47965 4.72055 1.40022 4.77914 1.34163C4.83772 1.28305 4.91715 1.25009 5.00001 1.25H9.37501C9.45786 1.25009 9.53729 1.28305 9.59587 1.34163C9.65446 1.40022 9.68741 1.47965 9.68751 1.5625V2.5H4.68751V1.5625ZM9.06864 3.125C9.09442 3.78069 9.20178 4.43062 9.38825 5.05977C8.68732 5.16063 7.9739 5.13323 7.28278 4.97891C6.54616 4.81605 5.78691 4.78189 5.03864 4.87793C5.19334 4.30551 5.28316 3.7175 5.30641 3.125H9.06864Z" />
      <path d="M5.20447 6.27786L4.86737 7.06445L5.44188 7.31066L5.77898 6.52407L5.20447 6.27786Z" />
      <path d="M4.0625 10.3124V11.5624H4.68751V10.3124C4.68655 9.49295 4.85302 8.68198 5.17669 7.9292L4.60247 7.68311C4.24528 8.5136 4.06153 9.40832 4.0625 10.3124Z" />
      <path d="M17.1875 9.375C17.1875 8.34918 15.1438 7.8125 13.125 7.8125C11.1062 7.8125 9.0625 8.34918 9.0625 9.375C9.06258 9.41269 9.06551 9.45033 9.07125 9.48758L10.0016 19.0938C10.0093 19.1708 10.0454 19.2423 10.1029 19.2943C10.1603 19.3462 10.235 19.375 10.3125 19.375H15.9375C16.015 19.375 16.0897 19.3462 16.1471 19.2943C16.2046 19.2423 16.2407 19.1708 16.2484 19.0938L17.1788 9.48766C17.1845 9.45038 17.1874 9.41272 17.1875 9.375ZM16.3357 11.9336L16.1777 11.9863C15.2049 12.3123 14.1549 12.3282 13.1726 12.0319C12.1224 11.7164 11.0026 11.7164 9.95234 12.0319L9.92402 12.0404L9.77 10.2955C10.5413 10.7194 11.8381 10.9375 13.125 10.9375C14.4119 10.9375 15.7088 10.7193 16.4801 10.2955L16.3357 11.9336ZM13.125 8.4375C15.3351 8.4375 16.5465 9.04887 16.5618 9.36879L16.5582 9.40785C16.4805 9.73379 15.2751 10.3125 13.125 10.3125C10.9749 10.3125 9.76953 9.73379 9.69176 9.40781L9.68824 9.36875C9.70352 9.04887 10.9149 8.4375 13.125 8.4375ZM15.6548 18.75H10.5951L9.98324 12.6752L10.132 12.6306C11.0652 12.3515 12.0598 12.3515 12.993 12.6306C14.0624 12.9533 15.204 12.9473 16.2699 12.6133L15.6548 18.75Z" />
      <path d="M11.2963 13.3718L10.6738 13.4268L10.7323 14.0898L11.3548 14.0349L11.2963 13.3718Z" />
      <path d="M11.4098 14.6565L10.7878 14.7183L11.0359 17.2182L11.6579 17.1565L11.4098 14.6565Z" />
    </g>
  </svg>, "Milk");
export default Milk;