import createSvgIcon from "@mui/material/utils/createSvgIcon";
const PremiumPackageIcon = createSvgIcon(<svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 8C0 3.58172 3.58172 0 8 0H92C96.4183 0 100 3.58172 100 8V92C100 96.4183 96.4183 100 92 100H8C3.58172 100 0 96.4183 0 92V8Z" fill="#DBF0FE" />
    <g clipPath="url(#clip0_8574_72329)">
      <path d="M80 36.4062C80 38.9086 78.1344 40.9375 75.8334 40.9375C75.8075 40.9375 75.7859 40.9232 75.7601 40.9227L70.4976 72.4036C70.2083 74.1176 68.8333 75.375 67.2188 75.375H32.7813C31.1708 75.375 29.7896 74.1221 29.5021 72.3991L24.2406 40.9262C24.2146 40.9262 24.1927 40.9375 24.0729 40.9375C21.7719 40.9375 19.9062 38.9086 19.9062 36.4062C19.9062 33.9039 21.8656 31.875 24.0729 31.875C26.2802 31.875 28.2396 33.9039 28.2396 36.4062C28.2396 37.4256 27.8728 38.3196 27.3503 39.0763L36.6868 47.1986C38.3441 48.6406 40.8014 48.0531 41.7503 45.9887L47.7503 32.9387C46.5729 32.1401 45.7396 30.7807 45.7396 29.1562C45.7396 26.6539 47.6979 24.625 50 24.625C52.3021 24.625 54.0729 26.6539 54.0729 29.1562C54.0729 30.7807 53.2434 32.1401 52.0625 32.9398L58.0625 45.9898C59.0116 48.0538 61.4698 48.6406 63.1261 47.1997L72.4625 39.0774C72.0313 38.3207 71.6667 37.3238 71.6667 36.4062C71.6667 33.9027 73.5313 31.875 75.8334 31.875C78.1354 31.875 80 33.9027 80 36.4062Z" fill="#4E97FD" />
    </g>
    <defs>
      <clipPath id="clip0_8574_72329">
        <rect width="60" height="58" fill="white" transform="translate(20 21)" />
      </clipPath>
    </defs>
  </svg>, "PremiumPackageIcon");
export default PremiumPackageIcon;