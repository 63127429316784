import createSvgIcon from "@mui/material/utils/createSvgIcon";
const WeddingGift = createSvgIcon(<svg viewBox="0 0 19 19" fill="none">
    <g clipPath="url(#clip0_6820_24126)">
      <path d="M16.9943 4.23548H13.1859C13.6819 3.79565 13.9954 3.15424 13.9954 2.44075C13.9954 0.436387 11.6731 -0.677206 10.1103 0.556983C9.9803 0.659623 9.95815 0.84822 10.0608 0.978188C10.1635 1.10823 10.3521 1.13031 10.482 1.02767C11.6517 0.103988 13.3956 0.93534 13.3956 2.44075C13.3956 3.39176 12.6539 4.17228 11.7186 4.23548H9.7968V2.44071C9.7968 1.11779 8.72055 0.0415348 7.39763 0.0415348C6.07471 0.0415348 4.99845 1.11779 4.99845 2.44071C4.99845 3.15424 5.31188 3.79561 5.80791 4.23545H1.99948C1.17267 4.23545 0.5 4.90811 0.5 5.73493V7.28079C0.5 8.03117 1.0541 8.6545 1.77456 8.76337V16.5405C1.77456 17.3673 2.44723 18.04 3.27405 18.04H15.7198C16.5466 18.04 17.2193 17.3673 17.2193 16.5405V10.0881C17.2193 9.92247 17.085 9.78819 16.9194 9.78819C16.7537 9.78819 16.6195 9.92247 16.6195 10.0881V16.5405C16.6195 17.0366 16.2158 17.4402 15.7198 17.4402H8.44727V8.78031H10.5465V16.361C10.5465 16.5266 10.6808 16.6609 10.8464 16.6609C11.0121 16.6609 11.1463 16.5266 11.1463 16.361V8.78031H14.4827C14.6483 8.78031 14.7826 8.64603 14.7826 8.48041C14.7826 8.31479 14.6483 8.18052 14.4827 8.18052H12.4959V4.83528H16.9943C17.4904 4.83528 17.894 5.23886 17.894 5.73497V7.28082C17.894 7.77693 17.4904 8.18052 16.9943 8.18052H16.4133C16.2477 8.18052 16.1134 8.31479 16.1134 8.48041C16.1134 8.64603 16.2477 8.78031 16.4133 8.78031H16.9943C17.8211 8.78031 18.4938 8.10764 18.4938 7.28082V5.73497C18.4938 4.90815 17.8211 4.23548 16.9943 4.23548ZM5.59825 2.44071C5.59825 1.44854 6.40542 0.641329 7.39763 0.641329C8.38984 0.641329 9.19701 1.44854 9.19701 2.44071V4.23545H7.2752C6.33997 4.17228 5.59825 3.39172 5.59825 2.44071ZM7.84747 17.4402H3.27405C2.77798 17.4402 2.37436 17.0366 2.37436 16.5405V8.78031H7.84747V17.4402ZM11.8961 8.18052H1.99948C1.50342 8.18052 1.09979 7.77693 1.09979 7.28082V5.73497C1.09979 5.23886 1.50342 4.83528 1.99948 4.83528H6.49794V7.2391C6.49794 7.40472 6.63222 7.539 6.79784 7.539C6.96345 7.539 7.09773 7.40472 7.09773 7.2391V4.83528H11.8961V8.18052Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_6820_24126">
        <rect width="18" height="18" fill="white" transform="translate(0.496094 0.0400391)" />
      </clipPath>
    </defs>
  </svg>, "WeddingGift");
export default WeddingGift;